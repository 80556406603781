import React from 'react'
import { Link } from 'gatsby'
import Seo from './seo'
import Footer from './footer'


class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <Seo />
        <Link to="/" className="toplogo">
          Postulate One
        </Link>
        <main>{children}</main>
        <Footer />
      </>
    )
  }
}

export default Template
