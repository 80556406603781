import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
    return (
        <div className='footer'>
            <a href='https://github.com/skrbnv' target='_blank'>
                <FontAwesomeIcon icon={faGithub} />
            </a>
            <a href='https://www.linkedin.com/in/skrebnev/' target='_blank'>
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
        </div>
    )
}
  
  export default Footer